@import url('https://fonts.googleapis.com/earlyaccess/notosanskr.css');
html,body,div,p,img,span,a,em,strong,pre,h1,h2,h3,h4,h5,h6,ul,ol,li,dl,dt,dd,table,tbody,thead,tfoot,tr,th,td,form,fieldset,legend,caption,input,textarea,button,select,object,video,iframe,section,figure,header,footer,aside{margin:0;padding:0;box-sizing:border-box;word-break:keep-all;outline:none;}
body{font-family:"Pretendard","Malgun Gothic","맑은 고딕","dotum","sans-serif";font-size:14px;line-height:1;color:#1F1F1F;width:100%;height:100%;overflow-x:hidden;}
img,fieldset,button,input,select,textarea,pre{border:0;border-radius:0;font-family:"Pretendard","Malgun Gothic","맑은 고딕","dotum","sans-serif"}
table{border-spacing:0}
ul,ol{list-style:none}
em,address,i,var,dfn{font-style:normal}
br{font-size:0;}
a,a:link,ins{text-decoration:none;color:#1F1F1F}
hr,input::-ms-clear,select::-ms-expand{display:none}
legend,caption,.blind{position:absolute;top:-9999px;font-size:0;line-height:0}
label{cursor:pointer}
img{width:100%}
div{caret-color: rgba(0,0,0,0);}
input,select,textarea{-webkit-appearance:none}
input:disabled,select:disabled{background-color:#F2F2F2}
input::clear{display:none}
.scroll_off{position:fixed;overflow:hidden;height:100%;}
b{color: #E98764;}
.testDog b {color: #B1674D !important;}
@font-face {
  font-family: 'Pretendard';
  src: url('./font/Pretendard-Light.woff') format('opentype'), url('./font/Pretendard-Light.woff') format('woff');
  font-weight: 300;
	font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('./font/Pretendard-Medium.woff') format('opentype'), url('./font/Pretendard-Medium.woff') format('woff');
  font-weight: normal;
	font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('./font/Pretendard-Bold.woff') format('opentype'), url('./font/Pretendard-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
legend,caption,.blind{position:absolute;top:-9999px;font-size:0;line-height:0}
.btn-open-popup{
  font-size: 24px;
}
/*common*/
article{padding:50px 0 45px;}
article .title{font-size:36px;text-align:center;font-weight:600;color:#1F1F1F;}
.contents_wrap{width:1300px;margin:0 auto;}

/*Button*/
.btn{display:block;width:100%;height:80px;line-height:80px;font-size:36px;font-weight:600;color:#fff;text-align:center;background:#E98764;border:1px solid transparent;border-radius:50px;}
.btn.line{color:#E98764!important;background:#fff;border:1px solid #E98764;}
.btn_box{text-align: center;}
.btn_box .btn{width: calc(55% - 5px);margin: 5px auto 35px; color:#fff;}


.browser{display: flex;
  justify-content: center;
  align-items:center;
  flex-direction:column;
  min-height: 100vh;}
  


/*Slide*/
.swiper-container{height:100%;}
.swiper-container-horizontal>.swiper-pagination-bullets{bottom:30px;height:15px;}
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet{margin:0 3px;}
.testResult .swiper-pagination-bullet{width:15px;height:15px;background:#fff;border:1px solid #F0B089; opacity: 1;}
.testResult .swiper-pagination-bullet-active{background:#F0B089;}
.testResult .swiper-slide:not(.swiper-slide-active) img { margin-top:3vh!important; height:50vh !important; } 
.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled{opacity:1;}
.swiper-button-disabled{opacity:0;display:none;}
.testResult .swiper-pagination{bottom:0px !important;}
.swiper-button-prev, 
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-container-rtl .swiper-button-next{top:calc(50% - 30px);width:60px;height:60px;margin:0;background:url("https://pub.wjcompass.com/bigclass_renewal/images/icon_slide_arrow.png") 50% 50% / 100% 100% no-repeat;}
.swiper-button-prev{left:-75px;}
.swiper-button-next{right:-75px;transform:rotate(180deg);}
.swiper-pagination-progressbar{width:100%;height:3px;bottom:0;left:0;background:#eee;}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill{background:#ccc;}
.swiper-scrollbar{display: none;}

/*::::: HEADER :::::*/
.header{width:100%;border-bottom:1px solid #f5f5f5;}
.header.on + .page_wrap{padding-top:85px;}
.header.on{position:fixed;top:0;left:0;width:100%;z-index:500;}
@keyframes header_active{
	from{top:-85px;}
	to{top:0;}
}
.header .h_wrap{width:1720px;margin:0 auto;}
.header .pc{height:85px;line-height:85px;background: rgba(198, 83, 0, 0.4);
  box-shadow: 0px 4px 11px rgba(231, 182, 145, 0.42);}
.header .pc.stage, .header .mobile.stage{background-color: #FEF8F1;}
.header .pc .logo{float:left;display:block;width:160px;}
.header .pc .logo img{vertical-align:middle;width:100%;image-rendering:-webkit-optimize-contrast;transform:translateZ(0);backface-visibility:hidden;}
.header .pc .member_box{float:right;}

.header .pc .member.on{display:block;}
.header .pc .member  .btn_share{position:relative;width:48px;height:35px;display:inline-block;margin-right:20px; color:#fff;font-size: 28px;}
.header .pc .member .user_box{position:relative;    display: inline-block;}
.header .pc .member .user_box:hover .user_menu{opacity:1;height:calc(50px * 3);visibility:visible;}
.header .pc .member .user_box:hover .user_menu button{background:#fff;}
.header .pc .member .user_box .btn_index{position:relative;width:48px;height:35px;display:inline-block;margin-right:20px;margin-left:2px; color:#fff;font-size: 30px;}
.header .pc .member .user_menu{opacity:0;height:0;visibility:hidden;position:absolute;top:85px;left:-43px;width:150px;background:#fff;box-shadow:0 6px 10px rgba(0,0,0,.1);transition:.3s all;z-index:10;}
.header .pc .member .user_menu li a{border-bottom:1px solid #efefef;}
.header .pc .member .user_menu li a,
.header .pc .member .user_menu li button{display:block;width:100%;height:100%;font-size:16px;height:auto;line-height:50px;text-align:center;padding:0 10px;cursor:pointer;transition:.3s all;}
.header .pc .member .user_menu li a:hover,
.header .pc .member .user_menu li button:hover{color:#a3905d;}
.header .pc .member .user_menu li button{background:transparent;transition-delay:.1s;}

.header .mobile{display:none;position:relative;height:60px;padding:0 15px 0 10px; background: rgba(198, 83, 0, 0.4);}
.header .mobile .logo{display:block;width:106px;line-height:60px;}
.header .mobile .logo img{width:100%;vertical-align:middle;image-rendering:-webkit-optimize-contrast;transform:translateZ(0);backface-visibility:hidden;}
.header .mobile .btn_menu{position:absolute;top:22px;right:15px;width:22px;height:16px;display:block;}
.header .mobile .btn_share{position:absolute;top:20px;right:50px;width:22px;height:16px;display:block; font-size: 20px; color:#fff;}
.header .mobile .btn_menu,.header .mobile .btn_menu span {display:inline-block;transition:all .5s;}
.header .mobile .btn_menu span {position:absolute;left:0;width:100%;height:3px;background-color:#fff;border-radius:3px;}
.header .mobile .btn_menu span:nth-of-type(1){top:0;}
.header .mobile .btn_menu span:nth-of-type(2){top:6px;}
.header .mobile .btn_menu span:nth-of-type(3){bottom:0;}

.header .mobile .btn_menu.close span {background-color: #000 !important;}
.header .mobile .btn_menu.close span:nth-of-type(1){transform: translateY(6px) rotate(-45deg);}
.header .mobile .btn_menu.close span:nth-of-type(2){opacity: 0;}
.header .mobile .btn_menu.close span:nth-of-type(3){transform: translateY(-7px) rotate(45deg);}


.header .mobile .btn_menu.on span:nth-of-type(1){transform: translateY(6px) rotate(-45deg);}
.header .mobile .btn_menu.on span:nth-of-type(2){opacity: 0;}
.header .mobile .btn_menu.on span:nth-of-type(3){transform: translateY(-7px) rotate(45deg);}
.header .mobile .gnb_box{display:none;position:fixed;top:61px;left:0;width:100%;height:100%;background:#fff;padding:15px;z-index:100;}
.header .mobile .gnb_box.on{display:block;}
.header .mobile .gnb_box .gnb{margin-top:10px;}
.header .mobile .gnb_box .gnb > li > a{display:block;width:100%;height:45px;line-height:45px;font-size:16px;font-weight:600;color:#3a3a3a;border-bottom:1px solid #f0f0f0;}
.header .mobile .gnb_box .gnb li.depth_2 > a:after{float:right;display:block;content:"";width:9px;height:12px;background:url("https://pub.wjcompass.com/bigclass_renewal/images/m_icon_arrow_menu.png") 0 0 / 100% 100% no-repeat;margin-top:16px;transition:.3s all;transform:rotate(90deg);}
.header .mobile .gnb_box .gnb li.depth_2.on > a{border:0;}
.header .mobile .gnb_box .gnb li.depth_2.on > a:after{transform:rotate(270deg);}
.header .mobile .gnb_box .gnb li.depth_2.on > .lnb{max-height:160px;}
.header .mobile .gnb_box .lnb{max-height:0;overflow:hidden;background:#f0f0f0;border-radius:5px;transition:.5s all;}
.header .mobile .gnb_box .lnb li:nth-child(n+2){border-top:1px solid #e1e1e1;}
.header .mobile .gnb_box .lnb li a{display:block;width:100%;height:40px;line-height:40px;font-size:14px;padding:0 12px;}
.header .mobile .btn_box{margin-top:25px;}
.header .mobile .btn_box .btn{display:block;width:100%;height:45px;line-height:45px;font-size:14px;cursor:pointer;}


/*::::: FOOTER :::::*/
.footer{background:#a3905d;padding:25px 0 40px;}
.footer .f_wrap{width:1080px;margin:0 auto;}
.footer .fnb_box:after{content:"";display:block;clear:both;}
.footer .fnb_box .fnb{float:left;}
.footer .fnb_box .fnb li{display:inline-block;}
.footer .fnb_box .fnb li:after{content:"";display:inline-block;width:1px;height:16px;background:#666;vertical-align:top;margin:14px 20px 0;}
.footer .fnb_box .fnb li:last-child:after{width:0;height:0;margin:0;}
.footer .fnb_box .fnb li a{font-size:14px;line-height:45px;color:#fff;font-weight:300;}
.footer .fnb_box .partner{float:right;position:relative;}
.footer .fnb_box .partner .btn_site{display:block;width:330px;height:43px;line-height:45px;font-size:14px;font-weight:300;color:#fff;background:#ccc;border-radius:5px;padding:0 20px;}
.footer .fnb_box .partner .btn_site:after{float:right;font-family:"Font Awesome 5 Free";font-weight:600;content:"\f107";}
.footer .fnb_box .partner.on .btn_site:after{content:"\f106";}
.footer .fnb_box .partner.on .site_list{max-height:80px;}
.footer .fnb_box .partner .site_list{max-height:0;position:absolute;top:46px;left:0;width:100%;background:#fff;border-radius:5px;overflow:hidden;transition:.5s all;z-index:10;}
.footer .fnb_box .partner .site_list li a{display:block;width:100%;height:40px;line-height:40px;font-weight:300;padding:0 20px;}
.footer .fnb_box .partner .site_list li:hover{background:#f5f5f5;}
.footer .info_box{position:relative;color:#fff;/*border-top:1px solid #6C7280;margin-top:23px;*/padding-top:30px;padding-right:80px;}
.footer .info_box .info p{font-size:14px;line-height:2;font-weight:300;word-break:keep-all;}
.footer .info_box .info p span{word-break:keep-all;}
.footer .info_box .info a{color:#fff;font-weight:300;}
.footer .info_box .info p span:nth-child(n+2):before{content:"";display:inline-block;width:1px;height:14px;background:#4E5464;vertical-align:top;margin:6px 20px 0;}
.footer .info_box .copyright{margin-top:8px;}
.footer .info_box .copyright span{font-size:14px;line-height:1.6;font-weight:300;color:#ccc;}
.footer .info_box .copyright span:nth-child(1){margin-right:40px;}
.footer .btn_scroll_top{position:absolute;top:25px;right:0;content:"";display:block;width:62px;height:62px;background:#17a2b8 url("./image/icon_arrow_top.png") 50% 50% / 64px 64px no-repeat;}


.testStart {  min-height: 80vh;padding:100px 0 50px;background: #FEF8F1;margin-top:80px; overflow-y: hidden;}
.testStart .contents_wrap{background: url("./image/bg_brown.png") 50% 50% no-repeat;}
.testStart .btn_box a{color:#fff;}
.testStart .title{font-size:54px;line-height:1.3;text-align:center;}
.tit_txt {font-size:28px;line-height:1.3; color:#1F1F1F; text-align:center;margin-bottom:8px;}
.testStart .test_detail{margin-top:10px;color: #1F1E1E;font-size:18px;line-height:1.5;text-align:center;font-family: 'Pretendard';
  font-style: normal;font-weight: 400;}

.hint {margin-top:3px;color: #a1a1a1;font-size:10px;} 
.testChoice .test_detail{margin-top:37px;color: #a1a1a1;font-size:18px;line-height:1.5;text-align:center;font-family: 'Pretendard';
    font-style: normal;font-weight: 400;}
.testStart .test_count{background: url("./image/bubble.png") 50% 50% no-repeat;margin-top:10px;color:#fff; font-size: 16px; height: 65px;line-height: 50px;}
.testStart .scroll_next{margin:35px 0 0;text-align: center;font-size:36px;}
.testStart .scroll_next  .fas {position: relative; animation: scroll_next_motion 1s infinite;}
.testStart .img_box,.testComm .img_box{width:50%; max-width:512px; text-align: center;margin: 0 auto;}
@keyframes scroll_next_motion {
  from {top: 10px;}
  to {top: 70px;}
}

.testComm{padding:100px 0 50px; background:#FEF3EC;} 
.testComm .scroll-box{margin-bottom:90px;}
.testComm .scroll-box ul { white-space: nowrap; will-change: transform;; display: block; margin-bottom: 50px; -webkit-transform: translateY(calc(100% - 8rem)); transform: translateY(calc(100% - 8rem));}
.testComm .scroll-box ul li {
  color:#282828;line-height: 20px;font-size:16px;list-style-type: none; display: inline; margin:auto 15px; padding: 14px 27px; border-radius:25px;
}
.testComm .scroll-box .neon-sign-left{animation: scroll_left_motion 10s linear infinite;}
@keyframes scroll_left_motion {
  from { transform: translateX(5%); -webkit-transform: translateX(5%); }
  to { transform: translateX(-95%); -webkit-transform: translateX(-95%); }
}
@-webkit-keyframes scroll_left_motion {
  from { transform: translateX(5%); -webkit-transform: translateX(5%); }
  to { transform: translateX(-95%); -webkit-transform: translateX(-95%); }
}
.testComm .scroll-box ul:nth-child(1) li:nth-child(4n+1), .testComm .scroll-box ul:nth-child(2) li:nth-child(4n+1), .testComm .scroll-box ul:nth-child(3) li:nth-child(4n+2){
  background-color: #fffbef;
  ;
}
.testComm .scroll-box ul:nth-child(1) li:nth-child(4n+2) , .testComm .scroll-box ul:nth-child(2) li:nth-child(4n+4), .testComm .scroll-box ul:nth-child(3) li:nth-child(4n+3){
  background-color: #F0B089;
}
.testComm .scroll-box ul:nth-child(1) li:nth-child(4n+3), .testComm .scroll-box ul:nth-child(2) li:nth-child(4n+3) , .testComm .scroll-box ul:nth-child(3) li:nth-child(4n+1){
  background-color: #FFDACD;
}
.testComm .scroll-box ul:nth-child(1) li:nth-child(4n+4) , .testComm .scroll-box ul:nth-child(2) li:nth-child(4n+2), .testComm .scroll-box ul:nth-child(3) li:nth-child(4n+4){
  background-color: #fff;
}
.testComm .scroll-box .neon-sign-right{animation: scroll_right_motion 10s linear infinite;}
.testComm .sub, .testChoice .sub, .testResult .sub{
  margin:40px 20px;
}
.testComm .tit_txt, .testChoice .tit_txt{line-height: 2;}
@keyframes scroll_right_motion {
  from { transform: translateX(-95%); }
  to { transform: translateX(5%); }
}
.intro {  
  display: flex;
  justify-content: center;
  align-items:center;
  flex-direction:column;
  min-height: 100vh;}
.result_loading {  
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction:column;
    min-height: 100vh;
    background: #343333 !important;
    ;
  }
.result_loading .stage-name2{
  font-weight: 400;
font-size: 25px;
line-height: 24px;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
text-align: center;
letter-spacing: -0.04em;
color:#ffce4f;
}
.result_loading .stage-name{
  font-weight: 400;
font-size: 25px;
line-height: 24px;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
text-align: center;
letter-spacing: -0.04em;
color:#c4c4c4 !important;
}
.result_loading .icon img {
  width:100%;
  margin: 0 auto;
  display: block;
} 
.stageCat .sound{font-weight: 600; color:#B1674D;}
.stageDog .sound{font-weight: 600; color:#E98764;}
.stageCat .intro{ background:url("./image/cat-pattern.png") 50% 50% no-repeat;}
.stageDog .intro { background:url("./image/dog-pattern.png") 10% 0 / 100% 100% no-repeat}
.stageCat .intro .icon, .stageDog .intro .icon{
  width:50%;
  margin: 0 auto;
  display: block;
}
.testCat, .answer,.testDog, .answer, .testChoice{  min-height: 100vh;}
.stageCat .testCat{  background:url("./image/cat-pattern.png") 50% 0 no-repeat;}
.stageDog .testDog{  background:url("./image/dog-pattern.png") 0 0 / 100% 77% no-repeat;}
.stageCat .testCat .stage-top, .stageDog .testDog .stage-top{margin-top: 10px;}
.stageCat .testCat h2, .stageDog .testDog h2{text-align: center; padding: 0 45px; line-height: 1.4; margin-bottom: 10px;}
.stageCat .testCat .list ,.stageDog .testDog .list {display: block; margin: 40px 0px 80px; }
.stageCat .testCat .btn_box,
.stageDog .testDog .btn_box
{  margin-bottom:25px;}
.stageCat .testCat .btn_box .btn
{background: #FFEDE2;
  border: 2px solid #F0B089;
  border-bottom: 5px solid #f0b089;
  border-radius: 40px;
  color:#383839;
  font-size: 18px;
  box-sizing: border-box;
}
.stageDog .testDog .btn_box .btn
{background: #FFEDE2;
  border: 2px solid #E98764;
  border-bottom: 5px solid #E98764;
  border-radius: 40px;
  color:#383839;
  font-size: 18px;
  box-sizing: border-box;
}
.stage-title {margin:0 15px; float: right;display: block;}
.prograss{ background:url("./image/prograssbar.png") 50% 0% / 95% 100% no-repeat; width: 100%; padding: 0 10px;
  height:5px; margin-top:40px;}
.answer .prograss{ background:url("./image/prograssbar2.png") 30% 0 / 90% 100% no-repeat; width: 100%; padding: 0 10px;
  height:5px; margin-top:40px; }
.stageCat .prograss span, .answer.cat .prograss span{visibility: hidden; float: left; background:url("./image/prograsspoint.png") 10% 10% / 100% 100% no-repeat; width:4.73%; height:30px; z-index: 10;position:relative;bottom: 10px;}
.stageDog .prograss span, .answer.dog .prograss span {visibility: hidden; float: left; background:url("./image/dog_ indicator.png") 10% 10% / 100% 100% no-repeat; width:4.73%; height:30px; z-index: 10;position:relative;bottom: 15px;}
.answer {padding-bottom: 30px;}
.answer.cat,.answer.cat .header .mobile{background: #664323; color:#f5f5f5; }
.answer.dog,.answer.dog .header .mobile{background: #E98764; color:#f5f5f5; }


.answer .header{border-bottom:0px;}
.answer .ment #ox{font-size: 120px;}
.answer .ment{font-weight: 900; font-size: 40px; text-align: center;}
.answer .header .mobile .btn_menu.close span{background-color:#f5f5f5 !important; }
.stageChoice,.stageCat,.stageDog{background: linear-gradient(180deg, #FEF8F1 0%, rgba(255, 237, 216, 0.61) 99.99%, #FFF4E7 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);}
.answer .stage-top .page-title{text-align: center;margin-bottom:20px;}
.answer .panel{background:url("./image/answer_bg.png") 10% 10% / 100% 100%  no-repeat; margin:0 10px 20px; padding-top: 10px; }
.answer .panel .layer{text-align: center;}
.answer .panel img{width: 65%; margin:5px auto 25px;}
.answer .panel .title{margin: 20px auto; padding: 0 15px;}
.answer .panel .result{color:black;line-height: 1.8; text-align: justify; padding: 20px 24px 30px; font-weight: 300; font-size: 18px;letter-spacing: -0.04em;word-break: break-all;}

.answer .panel .result .scroll_down{text-align: center;  caret-color: rgba(0,0,0,0);}
.answer .panel .result .scroll_up{text-align: center; display: block;transform:rotate(180deg);  caret-color: rgba(0,0,0,0);}
.answer .panel .result .etc , .answer .panel .result .scroll_up{display: none;}
.answer .panel .result .etc{word-break: break-all;}
.answer .panel .title .result-cmt{font-size: 20px;}
.answer .panel .title .result-cmt b{font-size: 24px;}

.answer .btn_box_r{ position:fixed; bottom:150px; right:10px; margin:30px 10px 15px;}
.answer .btn_box_r .btn {font-size: 20px; display: inline-block;  width: 10vh;height: 60px;line-height: 57.5px;background: #FFFFFF;
  border: 2px solid #000000;
  border-radius: 21px; color: #000;
  box-shadow: 1px 4px 4px rgb(0 0 0 / 25%);}
.prograss span.on {width:10% !important; display: inline-block;visibility:visible !important;}
.testChoice .stage-top{margin:80px 100px 40px; line-height: 1.2;}
.testChoice .stage-top .title{font-size:45px;}
.testChoice .list {display: block; margin: 0 20px; }
.testChoice .list ul { display: block; margin:0 auto 80px; text-align: center;}
.testChoice .list ul li {
  text-align: right;
  padding:35px 20px;
  font-size:32px;list-style-type: none; 
  display: inline-block; width: auto;   height:320px;
  box-shadow: 2px 7px 7px rgba(0, 0, 0, 0.25); border-radius: 20px;
  font-weight: 600;
}
.testChoice .list ul li .tag_name{
position: absolute;
width: 86px;
left :5px;
font-family: 'Pretendard';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 42px;
}
.testChoice .list ul li .tag {
  right: 20px;
  line-height: 42px;
  position: absolute;
}
.testChoice .list ul li .tag i{

  transform: rotate(-90deg);
  font-size: 24px;
  color:#777;

}
.testChoice .list .pet_l,.testChoice .list .pet_r{width:100%;}
.testChoice .list .pet_r.dogs{background:#fff url("./image/stage-dog2.png") 80% 50% / 50% 80%  no-repeat;}
.testChoice .list .pet_l.cats{background:#fff url("./image/stage-cats2.png")  80% 60% / 50% 75% no-repeat;}
.testChoice .list .pet_l.mouse{background:#fff url("./image/stage-mouse.png") 25% 75% / 55% 55% no-repeat;}
.testChoice .list .pet_r.hedgehog{background:#fff url("./image/stage-hedgehog.png") 25% 75% / 60% 60% no-repeat;}
.testChoice .list .pet_r.soon{
  opacity: 0.7;
  position: absolute;
  right: 0.5px;
  background: url("./image/soon.png") 50% 50% no-repeat;
  background-size: cover;
}
.testChoice .list .pet_l.soon{
  opacity: 0.7;
  position: absolute;
  left: 0.5px;
  background: url("./image/soon.png") 50% 50% no-repeat;
}


.bt_fixed{position:sticky;bottom:0;width:100%;height:100px;padding:0 15px;backdrop-filter: blur(10px);z-index:10;}
.bt_fixed .apply_box{text-align:center;}
.bt_fixed .apply_box:after{content:"";display:block;clear:both;}
.bt_fixed .apply_box h1{float:left;color:#fff;font-size:30px;line-height:70px;}
.bt_fixed .apply_box .btn_arrow{float:right;background:#e9470d;}
.bt_fixed .apply_box .btn_arrow span{font-size:24px;}
.bt_fixed .apply_box .btn_arrow span:after{margin-left:10px;}
.btn_arrow span{font-family:'yg-jalnan';font-size:26px;font-weight:600;color:#fff;}
.btn_arrow span:after{content:"";display:inline-block;width:18px;height:21px;background:url("https://pub.wjcompass.com/bigclass_renewal/images/icon_arrow_right_white.png") 0 0 / 100% 100% no-repeat;margin-left:10px;}
    
.last_page{
  text-align: center;
  background:url("./image/cat-pattern-lg.png") 50% 0 no-repeat;
}

.last_page .top .pointer{
  border-top: #B1674D 4px solid;
}
.last_page .top .idx{
font-weight: 700;font-size: 20px;line-height: 30px;color:#1F1E1E;
}
.last_page p{
  padding:15px 0 30px;
  color : #ACACAC;
}
.last_page article{
  margin-bottom:20px;
}
.last_page .book_list, .last_page .video_list, .swiper-edit{
  margin:5px 10px;
}
.last_page #charts {
  max-width: 375px;
  max-height: 135px;
}
.swiper-edit .swiper-wrapper{
  margin-bottom: 33px;
}

.last_page .repo a{
font-weight: 700;
font-size: 16px;
line-height: 28px;
}
.last_page .new_header{margin: 20px auto; max-width: 110px;}
.snsShare img{
  width: auto;
  margin:30px  6px;
}
.btn.gray{
  background-color: #c7c7c7 !important;
}
.btn.brown{
  background-color: #B1674D !important;
}
@media screen and (max-width: 1740px) {
  .header .h_wrap{width:1280px;}

 }
  @media screen and (max-width: 1320px) {
    .header .h_wrap{width:1080px;}
    .header .pc .logo{width:150px;}
    .header .pc .gnb{margin-left:50px;}
    .header .pc .gnb > li:nth-child(n+2){margin-left:30px;}
    .header .pc .gnb > li a{font-size:18px;}
    .header .pc .gnb .lnb li a{font-size:14px;padding:0 30px;}
    .header .pc .member .user_menu{width:130px;}
    .header .pc .member .user_menu li a, .header .pc .member .user_menu li button{font-size:14px;}
 }
 @media screen and (max-width: 1200px){
  .contents_wrap{width:100%;}
  .testStart{padding:70px 15px;}
 }
  @media screen and (max-width: 1080px) {
  .header .h_wrap{width:100%;padding:0 30px;}
  .header .pc .logo{width:130px;}
  .header .pc .gnb{margin-left:20px;}
  .header .pc .gnb > li a{font-size:16px;}
  .header .pc .gnb > li:nth-child(n+2){margin-left:15px;}
  .header .pc .gnb .lnb{top:85px;}
  .header .pc .gnb .lnb li a{padding:0 20px;}
  .header .pc .non_member .join{margin-left:5px;}
  .header .pc .member .user_menu{top:85px;}
  .footer .f_wrap{width:100%;padding:0 20px;}
  .footer .fnb_box .fnb li:after{margin:14px 8px 0;}
  .footer .fnb_box .partner{width:calc(100% - 570px);}
  .footer .fnb_box .partner .btn_site{width:100%;}
  }
  @media screen and (max-width: 768px) {
  article{padding:20px 10px 20px;}
  article .title{font-size:24px; line-height:1.4;}
  .contents_wrap{width:100%;}
  .btn_box .btn{width: calc(75% - 5px);margin: 3px auto 15px;height:60px;line-height:60px;font-size:24px;}
  .stageCat .testCat .btn_box .btn,
  .stageDog .testDog .btn_box .btn{
    width: calc(100% - 5px);margin: 10px auto 40px;    
    min-height: 70px;
    height: auto;
    line-height: 30px;
    padding: calc(5%) 0.5rem;
  }
  .header .pc{display:none;}
  .header .mobile{display:block;}
  .header .mobile.hidden{display:none;}
  .footer{padding:15px 0 30px;}
  .footer .f_wrap{padding:0 15px;}
  .footer .fnb_box .fnb{margin-top:10px;}
  .footer .fnb_box .fnb li:after{margin:6px 10px 0;}
  .footer .fnb_box .fnb li a{font-size:12px;line-height:30px;}
  .footer .fnb_box .partner{float:none;width:100%;}
  .footer .info_box{/*margin-top:10px;*/padding-top:10px;padding-right:50px;}
  .footer .info_box .info p{font-size:12px;}
  .footer .info_box .info p span:nth-child(n+2):before{margin:6px 10px 0;}
  .footer .info_box .copyright span{display:block;font-size:12px;margin:0;}
  .footer .btn_scroll_top{top:10px;width:42px;height:42px;background-size:42px 42px;}

  .testStart{padding:60px 16px 10px; margin-top: 60px;}
  .testStart .title, .testResult .title{font-size:30px;font-weight: 700;}
  .tit_txt{font-size:20px;color:#1F1F1F; font-weight: 700;}
  .sub .tit_txt{font-size: 18px; margin:auto 40px; 
    line-height: 30px;font-weight: 400;color: #000000;}
  .testComm .tit_tx, .testChoice .tit_txt{line-height: 30px;}
  .testStart .scroll_next{margin:15px 0 5px;text-align: center;font-size:24px;}
  .testStart .scroll_next  .fas {animation: scroll_next_motion_m 1s infinite;}
    @keyframes scroll_next_motion_m {
      from {top: 0px;}
      to {top: 30px;}
    }
    .testStart .img_box, .testComm .img_box {width:70%;}
    .testStart .test_detail {padding:0 5%;line-height: 30px; font-size:18px;}
    .testChoice .stage-top .title{font-size:28px;}
    .testChoice .list ul { display: block; margin:0 auto 45px;}
    .testChoice .list ul li {
      text-align: right;
      padding:20px 15px;
      font-size:24px;list-style-type: none; 
      display: inline-block; width: auto; height:240px;
      box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.25); border-radius: 10px;
      font-weight: 600;
    }
    .testChoice .test_detail{margin:37px 20px; font-size:14px;};
  }
  @media screen and (max-width: 480px){
    .testChoice .list {display: block; margin: 0 50px; }
    .testChoice .list ul { display: block; margin-bottom:30px;}
    .testChoice .list ul li {
      text-align: right;
      padding:15px 10px;
      font-size:18px;list-style-type: none; 
      display: inline-block; width: auto;   height:150px;
      font-weight: 600;
    }
    .testChoice .list .pet_l{margin-right:20px;}
   /*.testChoice .list .dogs .l_photo img{background-color: #FEF8F1;}
    .testChoice .list .cat .l_photo img{background-color: #E0FDAD;}
    .testChoice .list .r_next {width:50%; height: inherit; float:right;background-color:#fff; padding:15px;text-align: right; border-radius: 0 5px 5px 0;}
    .testChoice .list .l_photo {width:50%; height: inherit; overflow-y: hidden; float:left; border-radius: 5px 0 0 5px;}
    */
  }
  @media screen and (max-width: 320px){

  }
